<template lang="pug">
  unauthorized-layout
    template
      apply
</template>

<script>
import UnauthorizedLayout from '@/layouts/unauthorized-layout';
import Apply from '@/components/views/apply';
import Timer from '@/components/specific/timer';

export default {
  name: "ApplyPage",
  metaInfo: {
    title: 'Портал «Молодежь Москвы»',
  },
  components: {
    'timer': Timer,
    'unauthorized-layout': UnauthorizedLayout,
    'apply': Apply,
  },
  created() {
    const script_el = document.createElement('script');
    const jscode = document.createTextNode("dataLayer.push({ 'event': 'scrn', 'screenName': '/apply', 'buttonLocation': null, 'actionGroup': 'non_interactions', 'touchPoint': 'web'});")
    script_el.appendChild(jscode);
    document.head.appendChild(script_el)
  }
}
</script>